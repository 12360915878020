import React from "react"
import MainNavBar from "../components/mainnavbar" 
import "bootstrap/dist/css/bootstrap.min.css";

export default () => (
  <div>
  <MainNavBar />
    Contato.
  </div>
)
